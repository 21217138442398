import { LocalTimeFormat } from '../settings/global-settings';

export class Flight {
  constructor(workspaceId = 0) {
    this.route = [new Route(), new Route()];
    this.airlineId = null;
    this.airlineIata = '';
    this.airlineIcao = '';
    this.flightNumber = '';
    this.aircraftTypeId = null;
    this.aircraftTypeIata = '';
    this.payload = {
      baggage: null,
      cargo: null,
      mail: null,
      passengers: null,
    };
    this.fuelData = {
      ballast: null,
      onBoard: null,
      taxi: null,
      trip: null,
      preliminary: {
        onBoard: null,
        taxi: null,
        trip: null,
        ballast: null,
      }
    };
    this.tailId = null;
    this.movementTypeId = null;
    this.isDeleted = false;
    this.crew = [null, null];
    this.cateringWeight = null;
    this.fuel = [null, null, null, null];
    this.isAhmCalc = null;
    this.workspaceId = workspaceId;
    this.showTime = LocalTimeFormat.UTC;
    this.selected = false;
  }
  id: number;
  airlineId: number;
  airlineIata: string;
  airlineIcao: string;
  flightNumber: string;
  aircraftTypeId: number;
  aircraftTypeIata: string;
  tailId: number;
  movementTypeId: number;
  movementTypeIata: string;
  payload: {
    baggage: number;
    cargo: number;
    mail: number;
    passengers: number;
  };
  fuelData: {
    ballast: number;
    onBoard: number;
    taxi: number;
    trip: number;
    preliminary: {
      onBoard: number;
      taxi: number;
      trip: number;
      ballast: number;
    }
  };
  isDeleted: boolean;
  crew: Array<number>;
  cateringWeight: number;
  fuel: Array<number>;
  route: Array<Route>;
  isAhmCalc: boolean;
  edno: boolean;
  preparedBy: string;
  preparedAt: Date;
  workspaceId?: number;
  workspace: string;
  lastupdate: Date;
  assignee: string;
  showTime: LocalTimeFormat;
  selected: boolean;

  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  get preparedDate(): Date {
    if (this.isDateNull(this.preparedAt)) {
      return this.preparedAt;
    } else {
      return null;
    }
  }

  get startRoute() {
    if (this.route && Array.isArray(this.route)) {
      return this.route.sort((a, b) => a.order - b.order)[0];
    } else {
      return null;
    }
  }

  get finishRoute() {
    if (this.route && Array.isArray(this.route)) {
      return this.route.sort((a, b) => b.order - a.order)[0];
    } else {
      return null;
    }
  }

  get homeRoute() {
    if (this.route && Array.isArray(this.route)) {
        if (this.route.length >= 1) {
          const route = new Route();
          Object.assign(route, this.route[0]);
          return route;
        }
    }
    return null;
  }

  get nextRoute() {
    if (this.route && Array.isArray(this.route)) {
          const route = new Route();
          Object.assign(route, this.route[this.route.length - 1]);
          return route;
    } else {
      return null;
    }
  }

  get loading() {
    const result = {
      handLuggage: 0,
      luggage: 0,
      cargo: 0,
      mail: 0,
      adult: 0,
      female: 0,
      child: 0,
      infant: 0,
      business: 0,
      economy: 0
    };
    if (this.route && Array.isArray(this.route)) {
      // let flag = false;
      for (const route of this.route) {
        // if (route.airportId === this.homeAirport) {
        //   flag = true;
        // } else if (flag == true) {
          result.handLuggage += route.handLuggage[0] + route.handLuggage[1];
          result.luggage += route.baggageWeight[0] + route.baggageWeight[1];
          result.cargo += route.cargo[0] + route.cargo[1];
          result.mail += route.mail[0] + route.mail[1];
          result.adult += route.adult[0] + route.adult[1];
          result.female += route.female[0] + route.female[1];
          result.child += route.child[0] + route.child[1];
          result.infant += route.infant[0] + route.infant[1];
          result.infant += route.infant[0] + route.infant[1];
          result.business += route.business[0] + route.business[1];
          result.economy += route.economy[0] + route.economy[1];
        // }
      }
    }
    return result;
  }

  public isDeparture(value: number) {
    let result = false;
    let flag = false;
    for (const route of this.route) {
      // TODO надо сделать проверку массива на количество элементов
      // Ранее поиск от Базового
      // в текущий момет поиск от 1 точки маршрута, так как выведено понятие
      // базового аэропорта
      if (route.airportId === this.route[0].airportId) {
        flag = true;
        // Все аэропорты в маршруте после базового являются вылетными
      } else if (flag === true && route.airportId === value) {
        result = true;
      }
    }
    return result;
  }
}

export class Route {
  constructor() {
    this.order = null;
    this.airportId = null;
    this.airportIata = '';
    this.airportIcao = '';
    this.airportCode = ['', ''];
    this.dtArrival = [null, null, null];
    this.dtDeparture = [null, null, null];
    this.adult = [null, null, null, null];
    this.female = [null, null, null, null];
    this.child = [null, null, null, null];
    this.infant = [null, null, null, null];
    this.business = [null, null, null, null];
    this.economy = [null, null, null, null];
    this.handLuggage = [null, null, null, null];
    this.baggageWeight = [null, null, null, null];
    this.baggageUnit = [null, null, null, null];
    this.baggagePayed = [null, null, null, null];
    this.cargo = [null, null, null, null];
    this.mail = [null, null, null, null];
  }

  order: number;
  airportId: number;
  airportIata: string;
  airportIcao: string;
  airportCode: Array<string>;
  dtArrival: Array<Date>;
  dtDeparture: Array<Date>;
  adult: Array<number>;
  female: Array<number>;
  child: Array<number>;
  infant: Array<number>;
  business: Array<number>;
  economy: Array<number>;
  handLuggage: Array<number>;
  baggageWeight: Array<number>;
  baggageUnit: Array<number>;
  baggagePayed: Array<number>;
  cargo: Array<number>;
  mail: Array<number>;
  lastupdate: Date;

  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  get displayName() {
    return this.airportIata?.trim() || this.airportIcao?.trim() || this.airportCode[1]?.trim() || "";
  }

  get loading() {
    let result = {
      adult: this.adult[0] + this.adult[1],
      female: this.female[0] + this.female[1],
      child: this.child[0] + this.child[1],
      infant: this.infant[0] + this.infant[1],
      pax: this.adult[0] + this.adult[1] + this.female[0] + this.female[1] + this.child[0] + this.child[1],
      business: this.business[0] + this.business[1],
      economy: this.economy[0] + this.economy[1],
      baggageWeight: this.baggageWeight[0] + this.baggageWeight[1],
      handLuggage: this.handLuggage[0] + this.handLuggage[1],
      cargo: this.cargo[0] + this.cargo[1],
      mail: this.mail[0] + this.mail[1],
    };
    return result;
  }

  get dtArrivalScheduled(): Date {
    if (this.isDateNull(this.dtArrival[0])) {
      return this.dtArrival[0];
    } else {
      return null;
    }
  }

  set dtArrivalScheduled(value: Date) {
    this.dtArrival[0] = value;
  }

  get dtArrivalEstimated(): Date {
    if (this.isDateNull(this.dtArrival[1])) {
      return this.dtArrival[1];
    } else {
      return null;
    }
  }

  set dtArrivalEstimated(value: Date) {
    this.dtArrival[1] = value;
  }

  get dtArrivalFact(): Date {
    if (this.isDateNull(this.dtArrival[2])) {
      return this.dtArrival[2];
    } else {
      return null;
    }
  }

  set dtArrivalFact(value: Date) {
    this.dtArrival[2] = value;
  }

  get dtDepartureScheduled(): Date {
    if (this.isDateNull(this.dtDeparture[0])) {
      return this.dtDeparture[0];
    } else {
      return null;
    }
  }

  set dtDepartureScheduled(value: Date) {
    this.dtDeparture[0] = value;
  }

  get dtDepartureEstimated(): Date {
    if (this.isDateNull(this.dtDeparture[1])) {
      return this.dtDeparture[1];
    } else {
      return null;
    }
  }

  set dtDepartureEstimated(value: Date) {
    this.dtDeparture[1] = value;
  }

  get dtDepartureFact(): Date {
    if (this.isDateNull(this.dtDeparture[2])) {
      return this.dtDeparture[2];
    } else {
      return null;
    }
  }

  set dtDepartureFact(value: Date) {
    this.dtDeparture[2] = value;
  }

  get dtDepartureShow(): Date {
    return this.dtDepartureFact || this.dtDepartureEstimated || this.dtDepartureScheduled;
  }

  get dtArrivalShow(): Date {
    return this.dtArrivalFact || this.dtArrivalEstimated || this.dtArrivalScheduled;
  }

  //  Функциия обработки пассажиров - Взрослые
  get passengersAdultStandard(): number {
    if (this.adult && this.adult.length > 1) {
      return this.adult[0];
    }
  }

  set passengersAdultStandard(value) {
    this.adult[0] = value;
  }

  get passengersAdultTransit(): number {
    if (this.adult && this.adult.length > 1) {
      return this.adult[1];
    }
  }

  set passengersAdultTransit(value) {
    this.adult[1] = value;
  }

  //  Функциия обработки пассажиров - Женщины
  get passengersFemaleStandard(): number {
    if (this.female && this.female.length > 1) {
      return this.female[0];
    }
  }

  set passengersFemaleStandard(value) {
    this.female[0] = value;
  }

  get passengersFemaleTransit(): number {
    if (this.female && this.female.length > 1) {
      return this.female[1];
    }
  }

  set passengersFemaleTransit(value) {
    this.female[1] = value;
  }

  //  Функциия обработки пассажиров - Дети
  get passengersChildStandard(): number {
    if (this.child && this.child.length > 1) {
      return this.child[0];
    }
  }

  set passengersChildStandard(value) {
    this.child[0] = value;
  }

  get passengersChildTransit(): number {
    if (this.child && this.child.length > 1) {
      return this.child[1];
    }
  }

  set passengersChildTransit(value) {
    this.child[1] = value;
  }

  //  Функциия обработки пассажиров - Младенцы
  get passengersInfantStandard(): number {
    if (this.infant && this.infant.length > 1) {
      return this.infant[0];
    }
  }

  set passengersInfantStandard(value) {
    this.infant[0] = value;
  }

  get passengersInfantTransit(): number {
    if (this.infant && this.infant.length > 1) {
      return this.infant[1];
    }
  }

  set passengersInfantTransit(value) {
    this.infant[1] = value;
  }

  //  Функциия обработки пассажиров - Бизнес класс
  get passengersBusinessStandard(): number {
    if (this.business && this.business.length > 1) {
      return this.business[0];
    }
  }

  set passengersBusinessStandard(value) {
    this.business[0] = value;
  }

  get passengersBusinessTransit(): number {
    if (this.business && this.business.length > 1) {
      return this.business[1];
    }
  }

  set passengersBusinessTransit(value) {
    this.business[1] = value;
  }

  //  Функциия обработки пассажиров - Эконом класс
  get passengersEconomyStandard(): number {
    if (this.economy && this.economy.length > 1) {
      return this.economy[0];
    }
  }

  set passengersEconomyStandard(value) {
    this.economy[0] = value;
  }

  get passengersEconomyTransit(): number {
    if (this.economy && this.economy.length > 1) {
      return this.economy[1];
    }
  }

  set passengersEconomyTransit(value) {
    this.economy[1] = value;
  }

  //  Функциия обработки пассажиров - Ручная кладь
  get passengersHandLuggageStandard(): number {
    if (this.handLuggage && this.handLuggage.length > 1) {
      return this.handLuggage[0];
    }
  }

  set passengersHandLuggageStandard(value) {
    this.handLuggage[0] = value;
  }

  get passengersHandLuggageTransit(): number {
    if (this.handLuggage && this.handLuggage.length > 1) {
      return this.handLuggage[1];
    }
  }

  set passengersHandLuggageTransit(value) {
    this.handLuggage[1] = value;
  }

  //  Функциия обработки багажа - Еденицы багажа
  get baggageUnitStandard(): number {
    if (this.baggageUnit && this.baggageUnit.length > 1) {
      return this.baggageUnit[0];
    }
  }

  set baggageUnitStandard(value) {
    this.baggageUnit[0] = value;
  }

  get baggageUnitTransit(): number {
    if (this.baggageUnit && this.baggageUnit.length > 1) {
      return this.baggageUnit[1];
    }
  }

  set baggageUnitTransit(value) {
    this.baggageUnit[1] = value;
  }

  //  Функциия обработки багажа - Вес багажа
  get baggageWeightStandard(): number {
    if (this.baggageWeight && this.baggageWeight.length > 1) {
      return this.baggageWeight[0];
    }
  }

  set baggageWeightStandard(value) {
    this.baggageWeight[0] = value;
  }

  get baggageWeightTransit(): number {
    if (this.baggageWeight && this.baggageWeight.length > 1) {
      return this.baggageWeight[1];
    }
  }

  set baggageWeightTransit(value) {
    this.baggageWeight[1] = value;
  }

  //  Функциия обработки багажа - Платный багажа
  get baggagePayedStandard(): number {
    if (this.baggagePayed && this.baggagePayed.length > 1) {
      return this.baggagePayed[0];
    }
  }

  set baggagePayedStandard(value) {
    this.baggagePayed[0] = value;
  }

  get baggagePayedTransit(): number {
    if (this.baggagePayed && this.baggagePayed.length > 1) {
      return this.baggagePayed[1];
    }
  }

  set baggagePayedTransit(value) {
    this.baggagePayed[1] = value;
  }

  //  Функциия обработки груза - Вес груза
  get cargoStandard(): number {
    if (this.cargo && this.cargo.length > 1) {
      return this.cargo[0];
    }
  }

  set cargoStandard(value) {
    this.cargo[0] = value;
  }

  get cargoTransit(): number {
    if (this.cargo && this.cargo.length > 1) {
      return this.cargo[1];
    }
  }

  set cargoTransit(value) {
    this.cargo[1] = value;
  }

  //  Функциия обработки почты - Вес почты
  get mailStandard(): number {
    if (this.mail && this.mail.length > 1) {
      return this.mail[0];
    }
  }

  set mailStandard(value) {
    this.mail[0] = value;
  }

  get mailTransit(): number {
    if (this.mail && this.mail.length > 1) {
      return this.mail[1];
    }
  }

  set mailTransit(value) {
    this.mail[1] = value;
  }

  /**
   * Функция проверки корректности полученных данных
   *
   * Object.assign при отсутствии данных в входящем массиве, вставляет null в
   * текущие структуры.
   *
   * Такая ситуация возникает при интеграции, когда на входе не можем гарантировать
   * наличие данных от сторонней системы
   *
   */
  fixData() {
    if (this.dtArrival === null) {
      this.dtArrival = [null, null, null];
    }
    if (this.dtDeparture === null) {
      this.dtDeparture = [null, null, null];
    }
    if (this.adult === null) {
      this.adult = [null, null, null, null];
    }
    if (this.female === null) {
      this.female = [null, null, null, null];
    }
    if (this.child === null) {
      this.child = [null, null, null, null];
    }
    if (this.infant === null) {
      this.infant = [null, null, null, null];
    }
    if (this.business === null) {
      this.business = [null, null, null, null];
    }
    if (this.economy === null) {
      this.economy = [null, null, null, null];
    }
    if (this.handLuggage === null) {
      this.handLuggage = [null, null, null, null];
    }
    if (this.baggageWeight === null) {
      this.baggageWeight = [null, null, null, null];
    }
    if (this.baggageUnit === null) {
      this.baggageUnit = [null, null, null, null];
    }
    if (this.baggagePayed === null) {
      this.baggagePayed = [null, null, null, null];
    }
    if (this.cargo === null) {
      this.cargo = [null, null, null, null];
    }
    if (this.mail === null) {
      this.mail = [null, null, null, null];
    }
  }
}

export class Ahm {
  constructor() {
    this.airlineId = null;
    this.aircraftTypeId = null;
    this.revision = null;
    this.remark = '';
    this.uldTypes = [];
    this.lastupdate = new Date();

    const today = new Date();
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    today.setUTCMilliseconds(0);
    this.dtRange = [today, new Date('2099-01-01')];
  }
  id: number;
  airlineId: number;
  airlineName: string;
  aircraftTypeId: number;
  aircraftTypeName: string;
  revision: number;
  remark: string;
  uldTypes: Array<UldTypes>;
  cargoSymbols: Array<CargoSymbols>;
  cargoRestrictions: Array<CargoRestrictions>;
  dtRange: Array<Date>;
  lastupdate: Date;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    value.setUTCHours(0);
    value.setUTCMinutes(0);
    value.setUTCSeconds(0);
    value.setUTCMilliseconds(0);
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    value.setUTCHours(23);
    value.setUTCMinutes(59);
    value.setUTCSeconds(59);
    value.setUTCMilliseconds(99);
    this.dtRange[1] = value;
  }

  get deprecated() {
    return this.dtRangeFinish < new Date() ? true : false;
  }
}

export class AhmData {
  constructor() {
    this.units = new Units();
    this.centreOfGravityChart = [new CentreOfGravityChart()];
    this.idealTrimLine = [];
    this.effectOfFuel = [{
      density: null,
      default: null,
      balance: [new CentreOfGravityChartItem()]
    }];
    this.standartWeights = {
      crew: {
        cockpit: {
          weight: null,
          handLuggage: null,
        },
        cabin: {
          weight: null,
          handLuggage: null,
        },
        handLuggageIncluded: true,
      },
      passengers: {
        winter: new Passengers(),
        summer: new Passengers(),
        handLuggageIncluded: true
      }

    },
      this.dowChanges = {
        crew: [],
        pantry: [],
        potableWater: [],
      };
    this.configurations = [];
    this.holdsAndCompartments = new AhmDataHoldsAndCompartments();
    this.stabilizerTrim = [];
    this.uldTypes = [];
    this.dowItemsIncluded = [1]; // ЭТО ЗАГЛУШКА, НАДО БУДЕТ УБРАТЬ!!!!
    this.lastupdate = new Date();
    this.seats = {
      crew: {
        cockpit: [],
        cabin: []
      }
    };

    const start = new Date();
    start.setUTCHours(0);
    start.setUTCMinutes(0);
    start.setUTCSeconds(0);
    start.setUTCMilliseconds(0);
    this.dtRange = [start, new Date('2099-01-01')];

  }
  id: number;
  tailId: number;
  tailName: string;
  airlineId: number;
  airlineIata: string;
  airlineIcao: string;
  ahmId: number;
  ahm: string;
  units: Units;
  k: number;
  c: number;
  referenceArm: number;
  macRc: number;
  lemacLerc: number;
  dow: number;
  doi: number;
  mac: number;
  zeroFuelWeight: number;
  landingWeight: number;
  takeOffWeight: number;
  rampTaxiWeight: number;
  centreOfGravityChart: Array<CentreOfGravityChart>;
  idealTrimLine?: Array<IdealTrimLine>;
  effectOfFuel: Array<{
    density: number;
    default: boolean;
    balance: Array<CentreOfGravityChartItem>
  }>;
  taxiFuel: number;
  holdsAndCompartments: AhmDataHoldsAndCompartments;
  configurations: Array<AhmConfiguration>;
  dowItemsIncluded: Array<number>;
  dowChanges: {
    crew: Array<DowChangesCrew>,
    pantry: Array<DowChangesPantry>,
    potableWater: Array<DowChangesPotableWater>,
  };
  seats: {
    crew: {
      cockpit: Array<Location>;
      cabin: Array<Location>;
    }
  };
  standartWeights: {
    crew: {
      cockpit: {
        weight: number;
        handLuggage: number;
      };
      cabin: {
        weight: number;
        handLuggage: number;
      };
      handLuggageIncluded: boolean;
    };
    passengers: {
      winter: Passengers;
      summer: Passengers;
      handLuggageIncluded: boolean;
    }
  };
  uldTypes: Array<UldTypes>;
  stabilizerTrim: Array<StabilizerTrim>;
  dtRange: Array<Date>;
  lastupdate: Date;

  /**
   * Функции чтения/записи времени актуальности записи
   */

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    value.setUTCHours(0);
    value.setUTCMinutes(0);
    value.setUTCSeconds(0);
    value.setUTCMilliseconds(0);
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    value.setUTCHours(23);
    value.setUTCMinutes(59);
    value.setUTCSeconds(59);
    value.setUTCMilliseconds(99);
    this.dtRange[1] = value;
  }

  get crewStandart() {
    if (this.dowChanges.crew && this.dowChanges.crew.length > 0) {
      const res = this.dowChanges.crew.filter(el => el.standard)[0];
      if (res) {
        return res.cockpit + '/' + res.cabin;
      }
    }
    return '';
  }

  get deprecated() {
    return this.dtRangeFinish < new Date() ? true : false;
  }
}

export class IdealTrimLine {
  constructor() {
    this.weight = null;
    this.line = new IdealTrimLineItem();
    this.fwd = new IdealTrimLineItem();
    this.aft = new IdealTrimLineItem();
  }
  weight: number | null;
  line: IdealTrimLineItem;
  fwd?: IdealTrimLineItem;
  aft?: IdealTrimLineItem;
}

class IdealTrimLineItem {
  constructor() {
    this.mac = null;
    this.index = null;
  }
  mac: number | null;
  index: number | null;
}

export class AhmDataHoldsAndCompartments {
  constructor() {
    this.fwd = [];
    this.aft = [];
    this.limits = [];
    this.interceptions = [];
    this.map = new BayMap();
    this.acSize = [
      {name: 'aft', start: 0, finish: 0},
      {name: 'fwd', start: 0, finish: 0},
    ];
    this.doors = [];
  }
  fwd: Array<HoldsAndCompartments>;
  aft: Array<HoldsAndCompartments>;
  limits: Array<WeightLimit>;
  interceptions: Array <{
    cmp1: string,
    cmp2: string,
  }>;
  map: BayMap;
  acSize: Array<{
    name: string;
    start: number,
    finish: number,
  }>;
  doors: Array<{
    centroid: number,
    size: number,
  }>;
}

export class UldTypes {
  constructor(obj?) {
    this.id = obj?.id || null;
    this.name = obj?.name || '';
    this.weight = obj?.weight || null;
    this.serials = {
      start: obj?.serials?.start || null,
      finish: obj?.serials?.finish || null
    };
    this.maxWeight = obj?.maxWeight || null;
    this.maxVolume = obj?.maxVolume || null;
    this.baseSizeId = obj?.baseSizeId || null;
    this.category = obj?.category || '';
  }
  id: number;
  name: string;
  weight: number;
  category: string;
  serials: {
    start: number;
    finish: number;
  };
  maxWeight: number;
  maxVolume: number;
  baseSizeId: number;
}

export class CargoSymbols {
  code: string;
  description: string;
  distance: number;
}

export class CargoRestrictions {
  impCode1: string;
  impCode2: string;
  symbol: string;
}

export class WeightLimit {
  constructor() {
    this.compartments = [];
    this.maxWeight = null;
  }
  compartments: Array<string>;
  maxWeight: number;
}

export class BayMap {
  constructor() {
    this.width = 600;
    this.length = 600;
    this.types = [];
    this.proportions = 0;
    this.loading = false;
    this.trunkMap = null;
  }
  name: string;
  types: Array<string>;
  length: number;
  width: number;
  proportions: number;
  loading: boolean;
  trunkMap: any;
  x: number;
  y: number;
}

export class StabilizerTrim {
  constructor() {
    this.note = '';
    this.values = [];
  }
  note: string;
  values: Array<StabToValues>;
}

export class StabToValues {
  constructor() {
    this.weight = null;
    this.trims = [];
  }
  weight: number;
  trims: Array<StabToTrims>;
}

export class StabToTrims {
  constructor() {
    this.mac = null;
    this.trim = null;
  }
  mac: number;
  trim: number;
}

export class HoldsAndCompartments {
  constructor() {
    this.name = '';
    this.type = '';
    this.uldTypeId = null;
    this.uldType = '';
    this.centroid = null;
    this.orientation = 'LR';
    this.maxWeight = null;
    this.volume = null;
    this.maxVolume = null;
    this.index = null;
    this.group = '';
    this.bays = [];
    this.from = null;
    this.to = null;
    this.positions = [];
    this.uldTypeIds = [];
    this.uldTypes = [];
  }
  name: string;
  type: string;
  uldTypeId: number;
  uldType: string;
  uldTypeIds: Array<number>;
  uldTypes: Array<string>;
  centroid: number;
  orientation: string;
  maxWeight: number;
  volume: number;
  maxVolume: number;
  index: number;
  group: string;
  from: number;
  to: number;
  bays: Array<HoldsAndCompartments>;
  positions: Array<HoldsAndCompartments>;
}

export class DowChangesCrew {
  constructor(obj?) {
    obj && Object.assign(this, obj);
  }
  cockpit: number;
  cabin: number;
  extra: number;
  dow?: number;
  doi?: number;
  standard: boolean;
  name?: string;
  cockpitLocations: Array<Location>;
  cabinLocations: Array<Location>;

  get code(): string {
    // if (this.dow !== 0 && this.doi !== 0 && this.cockpit && this.cabin) {
    //   return this.cockpit?.toString() + ' / ' + this.cabin?.toString() + ' / ' + (this.extra ? this.extra.toString() : '');
    // } else {
    //   return '';
    // }
    let cockpit = 0;
    let cabin = 0;
    if (this.cockpitLocations && this.cockpitLocations.length) {
      cockpit = this.cockpitLocations.reduce((acc, item) => acc = acc + (item.seats || 0), 0);
    }
    if (this.cabinLocations && this.cabinLocations.length) {
      cabin = this.cabinLocations.reduce((acc, item) => acc =  acc + (item.seats || 0), 0);
    }
    return cockpit + '/' + cabin;
  }

  get title(): string {
    return this.name || this.code || '';
  }

  set title(value: string) {
    if (this.name !== value) {
      this.name = value;
    }
  }

  get nameDowDoi(): string {
    if (this.dow !== 0 && this.doi !== 0) {
      if (this.extra) {
        return this.cockpit.toString() + ' / ' + this.cabin.toString()
                + ' / ' + this.extra.toString()
                + ' (' + this.dow + ' / ' + this.doi + ')';
      } else {
        return this.cockpit.toString() + ' / ' + this.cabin.toString()
                + ' (' + this.dow + ' / ' + this.doi + ')';
      }
    } else {
      return '';
    }
  }
}

export class Location {
  constructor(obj?: Location) {
    obj && Object.assign(this, obj);
  }
  location: string;
  maxSeats: number;
  seats: number;
  index: number;
  remark: string;
}

export class DowChangesPantry {
  constructor() {
    this.code = '';
    this.index = null;
    this.remark = '';
    this.standard = false;
    this.weight = null;
  }
  code: string;
  weight: number;
  index: number;
  standard: boolean;
  remark: string;
}

export class DowChangesPotableWater {
  constructor() {
    this.code = '';
    this.index = null;
    this.remark = '';
    this.standard = false;
    this.weight = null;
  }
  code: string;
  weight: number;
  index: number;
  standard: boolean;
  remark: string;
}

export class Units {
  constructor() {
    this.weight = null;
    this.length = null;
    this.volume = null;
    this.liquidVolume = null;
  }
  weight: string;
  length: string;
  liquidVolume: string;
  volume: string;
  fuelDensity: string;
  moments: string;
}

export class CentreOfGravityChart {
  constructor() {
    this.fwd = {
      zeroFuel: [],
      takeOff: [],
      landing: [],
    };
    this.aft = {
      zeroFuel: [],
      takeOff: [],
      landing: [],
    };
    this.note = '';
    this.conditions = [];
  }
  fwd: {
    zeroFuel: Array<CentreOfGravityChartItem>;
    takeOff: Array<CentreOfGravityChartItem>;
    landing: Array<CentreOfGravityChartItem>;
  };
  aft: {
    zeroFuel: Array<CentreOfGravityChartItem>;
    takeOff: Array<CentreOfGravityChartItem>;
    landing: Array<CentreOfGravityChartItem>;
  };
  note: string;
  conditions: Array<CentreOfGravityChartCondition>;
}

export class CentreOfGravityChartCondition {
  constructor() {
    this.from = null;
    this.to = null;
    this.type = '';
  }
  from: number;
  to: number;
  type: string;
}

export class CentreOfGravityChartItem {
  constructor() {
    this.weight = null;
    this.index = null;
  }
  weight: number;
  index: number;
}

export class AhmConfiguration {
  name: string;
  sections: Array<ConfigurationSection>;
}

export class ConfigurationSection {
  constructor() {
    this.name = '';
    this.rows = [];
    this.maxPassengers = null;
    this.index = null;
  }
  name: string;
  rows: Array<number>;
  maxPassengers: number;
  index: number;

  get rowFrom(): number {
    if (this.rows && this.rows.length > 0) {
      return this.rows[0];
    } else {
      return null;
    }
  }

  set rowFrom(value: number) {
    this.rows[0] = value;
  }

  get rowTo(): number {
    if (this.rows && this.rows.length > 1) {
      return this.rows[1];
    } else {
      return null;
    }
  }

  set rowTo(value: number) {
    this.rows[1] = value;
  }
}

export class Passengers {
  constructor() {
    this.adult = null;
    this.female = null;
    this.child = null;
    this.infant = null;
    this.handLuggage = null;
    this.handLuggageIncluded = true;
  }
  adult: number;
  female: number;
  child: number;
  infant: number;
  handLuggage: number;
  handLuggageIncluded: boolean;
}

class Calculation {
  constructor() {
    this.captain = '';
    this.configuration = '';
    this.edno = null;
    this.lastupdate = new Date();
    this.ll = null;
    this.preparedBy = '';
    this.preparedAt = new Date();
    this.documentId = '';
    this.crew = {
      schema: {
        cockpit: null,
        cabin: null,
        name: '',
      },
      additionalSeats: {
        cockpit: [],
        cabin: [],
        passengerSeats: []
      }
    };
  }
  id: number;
  flightId: number;
  configuration: string;
  captain: string;
  edno: number;
  lastupdate: Date;
  ll: number;
  preparedBy: string;
  preparedAt: Date;
  documentId: string;
  crew: {
    schema: {
      cockpit: number,
      cabin: number,
      name: string
    },
    additionalSeats: {
      cockpit: Array<CrewAdditionalItem>,
      cabin: Array<CrewAdditionalItem>;
      passengerSeats: Array<CrewAdditionalItem>;
    }
  };
}

export class CrewAdditionalItem {
  constructor (obj?: CrewAdditionalItem) {
    this.seat = obj?.seat || '';
    this.type = obj?.type || '';
    this.role = obj?.role || '';
    this.destination = obj?.destination || '';
  }
  seat: string;
  type: string;
  role: string;
  destination: string;
}

export class CalculationAhm extends Calculation {
  constructor() {
    super();
    this.tailId = null;
    this.tailsAhmDataId = null;
    this.dowChanges = {
      pantry: {
        code: '',
      },
      potableWater: {
        code: '',
      }
    };
    this.passengers = {
      weights: new Passengers(),
      cabinArea: []
    };
    this.handLuggage = null;
    this.fuel = {
      onBoard: null,
      taxi: null,
      trip: null,
      ballast: null,
      preliminary: {
        onBoard: null,
        taxi: null,
        trip: null,
        ballast: null,
      }
    };
    this.holdsAndCompartments = [];
    this.fktIncludedInDowDoi = false;
    this.centreOfGravityChart = new CentreOfGravityChart();
    this.fuelDensity = null;
    this.captain = '';
    this.dow = null;
    this.doi = null;
    this.maczfw = null;
    this.dli = null;
    this.lizfw = null;
    this.ttl = null;
    this.zfw = null;
    this.mactow = null;
    this.tow = null;
    this.lw = null;
    this.litow = null;
    this.maclaw = null;
    this.lilaw = null;
    this.stabTo = null;
    this.notoc = {
      releasedByAnother: false,
      dangerous: [],
      other: [],
    };
    this.si = '';
  }
  tailId: number;
  tailsAhmDataId: number;
  dowChanges: {
    pantry: {
      code: string;
    },
    potableWater: {
      code: string;
    }
  }
  passengers: {
    weights: Passengers;
    cabinArea: Array<CabinArea>;
  };
  handLuggage: number;
  fuel: {
    onBoard: number;
    taxi: number;
    trip: number;
    ballast: number;
    preliminary: {
      onBoard: number;
      taxi: number;
      trip: number;
      ballast: number;
    }
  };
  holdsAndCompartments: Array<HoldsAndCompartmentsCalc>;
  fktIncludedInDowDoi: boolean;
  centreOfGravityChart: CentreOfGravityChart;
  fuelDensity: number;
  dow: number;
  doi: number;
  maczfw: number;
  dli: number;
  lizfw: number;
  ttl: number;
  zfw: number;
  mactow: number;
  tow: number;
  lw: number;
  litow: number;
  maclaw: number;
  lilaw: number;
  stabTo: number;
  mtow: number;
  mlw: number;
  mzfw: number;
  ahmLastupdate: Date;
  notoc: {
    releasedByAnother: boolean;
    dangerous: Array<NotocDangerous>;
    other: Array<NotocOther>;
  };
  si: string;
}

export class CalculationManual extends Calculation {
  constructor() {
    super();
    this.passengers = {
      weights: new Passengers(),
      cabinArea: []
    };
    this.seats = {
      current: null,
      max: null
    }
  }
  tail: string;
  aircraftType: string;
  passengers: {
    weights: Passengers;
    cabinArea: Array<CabinArea>;
  };
  seats: {
    current: number,
    max: number,
  };
}

export class NotocDangerous {
  constructor(obj?) {
    obj && Object.assign(this, obj);
  }
  to: string;
  properShippingName: string;
  awbNr: string;
  clDv: string;
  comp: string;
  unId: string;
  unIdNumber: string;
  subRsk: string;
  pieces: number;
  qtyTi: string;
  rrrCat: string;
  pckGrp: string;
  impDrill: string;
  cao: true;
  pos: string;
  uldCode: string;
}

export class NotocOther {
  constructor(obj?) {
    obj && Object.assign(this, obj);
  }
  to: string;
  contents: string;
  awbNr: string;
  pieces: number;
  qty: number;
  impCode: string;
  pos: string;
  uldCode: string;
}

export class CabinArea {
  constructor(section?) {
    this.name = section?.name || '';
    this.maxPassengers = section?.maxPassengers || null;
    if (section?.rows.length > 1) {
      this.rows = [...section.rows];
    } else if (section?.rows.length > 0) {
      this.rows = [section.rows[0]];
    } else {
      this.rows = [];
    }
    this.passengers = section?.passengers || new Passengers();
    this.index = section?.index || null;
  }
  name: string;
  maxPassengers: number;
  rows: Array<number>;
  passengers: Passengers;
  index: number;

  get rowsString() {
    if (this.rows && this.rows.length > 1) {
      return this.rows[0] + ' - ' + this.rows[1];
    } else {
      return '';
    }
  }
}

export class HoldsAndCompartmentsCalc {
  constructor(params?) {
    this.name = params?.name || '';
    this.type = params?.type || '';
    this.destination = params?.destination || '';
    this.weight = params?.weight || null;
    this.uldType = params?.uldType || '';
    this.uldNum = params?.uldNum || '';
    this.uldWeight = params?.uldWeight || null;
    this.allWeight = params?.allWeight || null;
    this.imp = params?.imp || null;
    this.owner = params?.owner || null;
    this.volume = params?.volume || null;
    this.pieces = params?.pieces || null;
    this.inDow = params?.inDow || false;
    this.r = params?.r || false;
  }
  name: string;
  type: string;
  destination: string;
  weight: number;
  uldType: string;
  uldVolume: number;
  uldNum: string;
  uldWeight: number;
  allWeight: number;
  imp: string;
  owner: string;
  volume: number;
  pieces: number;
  inDow: boolean;
  r: boolean;
}

export class FilterParams {
  constructor() {
    this.start = null;
    this.finish = null;
    this.flight = null;
    this.airport = null;
    this.airline = null;
    this.aircraft = null;
    this.tail = null;
    this.assignee = null;
    this.cancelled = false;
    this.deleted = false;
  }
  start?: Date;
  finish?: Date;
  flight?: string;
  airport?: number;
  airline?: number;
  aircraft?: number;
  tail?: string;
  assignee?: string;
  cancelled?: boolean;
  deleted?: boolean;
}

export class AdminFilterParams {
  constructor() {
  }
  tail: string;
  removed: boolean;
}

export class Document {
  constructor(private last?: boolean, private wordLast?: string) {
    this.edno = null;
    this.preparedBy = '';
    this.preparedAt = new Date();
  }
  edno: number;
  preparedBy: string;
  preparedAt: Date;

  get name(): string {
    return this.last ? this.edno + ' (' + this.wordLast + ')' : this.edno.toString();
  }
}

export class DeltaDowDoi {
  constructor(standard?, def = {}) {
    this.standard = {dow: standard?.dow || 0, doi: standard?.doi || 0};
    this.pantry = {dow: 0, doi: 0, standardDow: 0, standardDoi: 0};
    this.potableWater = {dow: 0, doi: 0, standardDow: 0, standardDoi: 0};
    this.ballast = {dow: 0, doi: 0};
    this.crew = {dow: 0, doi: 0};
  }
  standard: {dow: number, doi: number};
  pantry: {dow: number, doi: number, standardDow: number, standardDoi: number};
  potableWater: {dow: number, doi: number, standardDow: number, standardDoi: number};
  ballast: {dow: number, doi: number};
  crew: {dow: number, doi: number};

  get calculated() {
    let dow = this.standard.dow;
    let doi = this.standard.doi;

    // При стандартном занчении dow = 0 и не нужно добавлять в расчет
    // значения кухни и воды
    if (this.pantry.dow !== 0) {
      dow = dow - this.pantry.standardDow + this.pantry.dow;
      doi = doi - this.pantry.standardDoi + this.pantry.doi;
    }
    if (this.potableWater.dow !== 0) {
      dow = dow - this.potableWater.standardDow + this.potableWater.dow;
      doi = doi - this.potableWater.standardDoi + this.potableWater.doi;
    }

    dow = dow + this.ballast.dow;
    doi = doi + this.ballast.doi;

    dow = dow + this.crew.dow;
    doi = doi + this.crew.doi;

    return {
      dow: Number(dow.toFixed(0)),
      doi: Number(doi.toFixed(2))
    };
  }

  get pantryDelta() {
    if (this.pantry.dow === 0) {
      return {
        dow: 0,
        doi: 0
      };
    } else {
      // TODO
      // На бэке нет математического округления, режится по кол символов,
      // из за этого разница в данных на форме и в отчетных документах.
      // Как бек сможет округление, надо конструкцию:
      // .toFixed(3).toString().slice(0, -1) заменить на .toFixed(2)
      return {
        dow: Number(this.pantry.dow - this.pantry.standardDow).toFixed(0),
        doi: Number(this.pantry.doi - this.pantry.standardDoi).toFixed(3).toString().slice(0, -1)
      };
    }
  }

  get potableWaterDelta() {
    if (this.potableWater.dow === 0) {
      return {
        dow: 0,
        doi: 0
      };
    } else {
      // TODO
      // На бэке нет математического округления, режится по кол символов,
      // из за этого разница в данных на форме и в отчетных документах.
      // Как бек сможет округление, надо конструкцию:
      // .toFixed(3).toString().slice(0, -1) заменить на .toFixed(2)
      return {
        dow: Number(this.potableWater.dow - this.potableWater.standardDow).toFixed(0),
        doi: Number(this.potableWater.doi - this.potableWater.standardDoi).toFixed(3).toString().slice(0, -1)
      };
    }
  }

  get crewDelta() {
    if (this.crew.dow === 0) {
      return {
        dow: 0,
        doi: 0
      };
    } else {
      // TODO
      // На бэке нет математического округления, режится по кол символов,
      // из за этого разница в данных на форме и в отчетных документах.
      // Как бек сможет округление, надо конструкцию:
      // .toFixed(3).toString().slice(0, -1) заменить на .toFixed(2)
      return {
        dow: Number(this.crew.dow).toFixed(0),
        doi: Number(this.crew.doi).toFixed(3).toString().slice(0, -1)
      };
    }
  }
}


export class Workspace {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  name: string;
  statuses: object;
  counters: object;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class Referance {
  name: any;

  get nameEnglish() {
    if (this.name && Array.isArray(this.name) && this.name[0]) {
      return this.name[0];
    }
    return null;
  }

  get nameLocal() {
    if (this.name && Array.isArray(this.name) && this.name[1]) {
      return this.name[1];
    }
    return null;
  }
}

export class GroupUsers {
  constructor() {
    this.id = '';
    this.name = '';
    this.workspaces = [];
  }
  id: string;
  name: string;
  workspaces: Array<UserWorkspace>;
}

export class UserWorkspace {
  constructor(data?) {
    this.id = +data?.id ?? null;
    this.name = data?.name ?? null;
    this.mode = data?.mode ?? null;
  }
  id: any;
  name: string;
  mode: string;
}

export class User {
  constructor() {
    this.username = '';
    this.firstName = '';
    this.lastName = '';
    this.enabled = false;
    this.groups = [];
    this.groupsArray = [];
  }
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  groups: Array<GroupUsers>;
  groupsArray: Array<string>;

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }
}

export class BalanceСhart {
  constructor() {
    this.weight = null;
    this.index = null;
    this.type = null;
    this.aft = null;
    this.fwd = null;
  }
  weight: number;
  index: number;
  type: string;
  aft: number;
  fwd: number;
}
